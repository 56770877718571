// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    display: flex;
    /* align-items: center; */
    /* justify-content: space-evenly; */
    /* background-color: blueviolet; */
    /* height: 500px; */
    /* padding: 3rem; */
    position: fixed;
    bottom:0;
}

.footer .nav-items {
    position: absolute;
    bottom: 0;
    width: 100vw;
    display:flex;
    list-style-type: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-inline-start: 0px;
    background-color: lightgrey;
    margin-top: 0px;
    font-size: 0.7rem;
}

@media (min-width: 650px) {
    .nav-items {
        flex-direction: row;
    }
}

.nav-items li {
    /* margin: 0.2rem; */
    /* display: inline-block; */
    padding: 5px 10px;
    /* border: 2px solid black; */
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mCAAmC;IACnC,kCAAkC;IAClC,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,YAAY;IACZ,qBAAqB;IACrB,eAAe;IACf,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,2BAA2B;IAC3B,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI,oBAAoB;IACpB,2BAA2B;IAC3B,iBAAiB;IACjB,6BAA6B;AACjC","sourcesContent":[".footer {\r\n    display: flex;\r\n    /* align-items: center; */\r\n    /* justify-content: space-evenly; */\r\n    /* background-color: blueviolet; */\r\n    /* height: 500px; */\r\n    /* padding: 3rem; */\r\n    position: fixed;\r\n    bottom:0;\r\n}\r\n\r\n.footer .nav-items {\r\n    position: absolute;\r\n    bottom: 0;\r\n    width: 100vw;\r\n    display:flex;\r\n    list-style-type: none;\r\n    flex-wrap: wrap;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-direction: row;\r\n    padding-inline-start: 0px;\r\n    background-color: lightgrey;\r\n    margin-top: 0px;\r\n    font-size: 0.7rem;\r\n}\r\n\r\n@media (min-width: 650px) {\r\n    .nav-items {\r\n        flex-direction: row;\r\n    }\r\n}\r\n\r\n.nav-items li {\r\n    /* margin: 0.2rem; */\r\n    /* display: inline-block; */\r\n    padding: 5px 10px;\r\n    /* border: 2px solid black; */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
