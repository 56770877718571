import React, { useEffect, useState } from 'react';
import { models, Report, Embed, IEmbedConfiguration, service, Page } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
// import 'powerbi-report-authoring';
import './PowerBIReport.css';
import axios, { Method } from "axios";
import { error } from 'console';
import {useTicker} from '../context/TickerContext';


// Root Component to demonstrate usage of wrapper component
function PowerBIReport (props:any): JSX.Element {
	const {ticker, setTicker} = useTicker();
    

	// PowerBI Report object (to be received via callback)
	const [report, setReport] = useState<Report>();

	// Report config useState hook
	// Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
	const [sampleReportConfig, setReportConfig] = useState<IEmbedConfiguration>({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		// settings: {
		// 	filterPaneEnabled: false,
		// 	navContentPaneEnabled: false
		// },
		settings: {
			panes:{
				pageNavigation:{
					visible: false,
					position: models.PageNavigationPosition.Left
				}
			}
		}
	});

	// Map of event handlers to be applied to the embedding report
	const eventHandlersMap = new Map([
		['loaded', function () {
			console.log('Report has loaded');
		}],
		['rendered', function () {
			console.log('Report has rendered');
			
			// Update display message
			setMessage('The report is rendered')
		}],
		['error', function (event?: service.ICustomEvent<any>) { 
			if (event) {
				console.error(event.detail);
			}
		}]
	]);


	const method:Method = "GET";

	let mockSignIn = () => {
		const call_pbi_proxy = async function (){
			try {
				const response = await axios.get("https://ownvestments-backend-function-app.azurewebsites.net/api/get_pbi_embed_token")
				setReportConfig({
					...sampleReportConfig,
					embedUrl: response.data.embedUrl.concat(`&pageName=${props.pageName}&$filter=fundamentals/ticker eq '`, ticker, "'"),
					accessToken: response.data.token,
				});
			} catch (err) {
				console.log(err)
			}
		}

		call_pbi_proxy();
	}

	useEffect(mockSignIn,[]);
	
	
	let updateReport = () => {
		setReportConfig({
			...sampleReportConfig,
			embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${process.env.REACT_APP_PBI_REPORT_ID}&pageName=${props.pageName}&$filter=fundamentals/ticker eq '`.concat(ticker, "'")
		});
	};

	//mockSignIn function runs when ticker props changes
	useEffect(updateReport, [ticker]);

	useEffect(updateReport, [props.pageName]);

	const changeSettings = () => {

		// Update the state "sampleReportConfig" and re-render DemoApp component
		setReportConfig({
			...sampleReportConfig,
			settings: {
				panes: {
					filters: {
						expanded: false,
						visible: false
					}
				},
			}
		});
	};

	// // Delete the first visual using powerbi-report-authoring library
	// const deleteVisual = async () => {

	// 	if (!report) {
	// 		console.log('Report not available');
	// 		return;
	// 	}

	// 	const activePage = await getActivePage(report);

	// 	if (!activePage) {
	// 		console.log('No active page');
	// 		return;
	// 	}

	// 	// Get all visuals in the active page
	// 	const visuals = await activePage.getVisuals();

	// 	if (visuals.length === 0) {
	// 		console.log('No visual left');
	// 		return;
	// 	}

	// 	// Get first visible visual
	// 	const visual = visuals.find((v) => {
	// 		return v.layout.displayState?.mode === models.VisualContainerDisplayMode.Visible;
	// 	});

	// 	// No visible visual found
	// 	if (!visual) {
	// 		console.log('No visible visual available to delete');
	// 		return;
	// 	}

	// 	try {
			
	// 		// Documentation link: https://github.com/microsoft/powerbi-report-authoring/wiki/Visualization
	// 		// Delete the visual 
	// 		// await activePage.deleteVisual(visual.name);

	// 		console.log('Visual was deleted');
	// 	}
	// 	catch (error) {
	// 		console.error(error);
	// 	}
	// };

	async function getActivePage(powerbiReport: Report): Promise<Page | undefined> {
		const pages = await powerbiReport.getPages();
	
		// Get the active page
		const activePage = pages.filter(function (page) {
			return page.isActive
		})[0];

		return activePage;
	}

	const [displayMessage, setMessage] = useState(`The report is bootstrapped. Click the Embed Report button to set the access token`);

	
	return (
		<div  className = "pbi_report">

			<PowerBIEmbed
				embedConfig = { sampleReportConfig }
				eventHandlers = { eventHandlersMap }
				cssClassName = { "report-style-class" }
				getEmbeddedComponent = { (embedObject:Embed) => {
					console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
					setReport(embedObject as Report);
				} }
			/>

		</div>
	);
}

export default PowerBIReport;


