// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headline {
    width: 100vw;
    padding: 0rem 0rem;
    margin-top: 5vh;
}


.headline img {
    height: auto;
    width: 50vw;
}



@media (min-width: 650px) {
    .headline_element {
        flex-direction: row;
    }
}

.benefits {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 10rem;
    padding-right: 10rem;
    /* width: 90%; */
    /* list-style-type: none; */
}

.benefit {
    /* height: 200px; */
    width: 33%;
    border: 5px;
    border-color: black;
    border-style: solid;
    border-radius: 1rem;
    padding: 3rem;
    margin: 3rem;
}

.subscription_plans {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 10rem;
    padding-right: 10rem;
    /* width: 90%; */
    /* list-style-type: none; */
}

.subscription_plan {
    /* height: 200px; */
    width: 33%;
    border: 5px;
    border-color: black;
    border-style: solid;
    border-radius: 1rem;
    padding: 3rem;
    margin: 5rem;
}

`, "",{"version":3,"sources":["webpack://./src/components/LandingPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;;AAGA;IACI,YAAY;IACZ,WAAW;AACf;;;;AAIA;IACI;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;IACnB,oBAAoB;IACpB,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;IACnB,oBAAoB;IACpB,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,YAAY;AAChB","sourcesContent":[".headline {\r\n    width: 100vw;\r\n    padding: 0rem 0rem;\r\n    margin-top: 5vh;\r\n}\r\n\r\n\r\n.headline img {\r\n    height: auto;\r\n    width: 50vw;\r\n}\r\n\r\n\r\n\r\n@media (min-width: 650px) {\r\n    .headline_element {\r\n        flex-direction: row;\r\n    }\r\n}\r\n\r\n.benefits {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-evenly;\r\n    padding-left: 10rem;\r\n    padding-right: 10rem;\r\n    /* width: 90%; */\r\n    /* list-style-type: none; */\r\n}\r\n\r\n.benefit {\r\n    /* height: 200px; */\r\n    width: 33%;\r\n    border: 5px;\r\n    border-color: black;\r\n    border-style: solid;\r\n    border-radius: 1rem;\r\n    padding: 3rem;\r\n    margin: 3rem;\r\n}\r\n\r\n.subscription_plans {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-evenly;\r\n    padding-left: 10rem;\r\n    padding-right: 10rem;\r\n    /* width: 90%; */\r\n    /* list-style-type: none; */\r\n}\r\n\r\n.subscription_plan {\r\n    /* height: 200px; */\r\n    width: 33%;\r\n    border: 5px;\r\n    border-color: black;\r\n    border-style: solid;\r\n    border-radius: 1rem;\r\n    padding: 3rem;\r\n    margin: 5rem;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
