import React from 'react';
import './LegalDocs.css';

function Disclaimer(props:any){
    return (
        <div className="big_centered_text_div">
            <h1>Disclaimer</h1>
            <br></br>
            <br></br>
                <h4>No Investment Advice</h4>
                <p>The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other sort of advice and you should not treat any of the website's content as such. Valuerise does not recommend that any investments should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor before making any investment decisions.</p>
                <h4>Accuracy of Information</h4>
                <p>Valuerise will strive to ensure accuracy of information listed on this website although it will not hold any responsibility for any missing or wrong information. Valuerise provides all information as is. You understand that you are using any and all information available here at your own risk.</p>
                <h4>Non Endorsement</h4>
                <p>The appearance of third party advertisements and hyperlinks on Valuerise does not constitute an endorsement, guarantee, warranty, or recommendation by Valuerise. Do conduct your own due diligence before deciding to use any third party services.</p>
                <h4>Affiliate Disclosure</h4>
                <p>Valuerise may receive compensation for affiliate links. This compensation may be in the form of money or services and could exist without any action from a site visitor. Should you perform activities in relation to an affiliate link, it is understood that some form of compensation might be made to Valuerise. For example, if you click on an affiliate link, and sign up and trade on an exchange, Valuerise may receive compensation. Each affiliate link is clearly marked with an icon next to it.</p>
        </div>
    )
}

export default Disclaimer;