import React, { useState, useEffect } from 'react';
import './Header.css';
import { useTicker } from '../context/TickerContext';
import Select from 'react-select';
import axios, { Method } from "axios";

type OptionType = { value: string; label: string; };

/**
 * Represents the header component of the application.
 * 
 * @param {object} props - The props passed to the component.
 * @returns {JSX.Element} The rendered header component.
 */
function Header(props) {
    // State for the input value
    const [inputValue, setInputValue] = useState("");

    // State for the tickers options
    const [tickers, setTickers] = useState<OptionType[]>([]);

    // Custom hook to get and set the selected ticker
    const { setTicker } = useTicker();

    // Fetch the tickers data from the API on component mount
    useEffect(() => {
        const config = {
            method: "GET" as Method,
            url: 'https://ownvestments-backend-function-app.azurewebsites.net/api/tickers',
        };

        axios(config)
            .then((response) => {
                const allTickers = response.data;
                const options = allTickers.map(ticker => ({ value: ticker, label: ticker }));
                setTickers(options);
            });
    }, []);

    // Handle input value change
    const handleInputChange = (newValue: string) => {
        setInputValue(newValue);
    };

    // Handle option selection change
    const handleChange = (selectedOption: OptionType | null) => {
        if (selectedOption) {
            setInputValue(selectedOption.value);
            setTicker(selectedOption.value.substring(0, selectedOption.value.indexOf(" ")));
        } else {
            setInputValue('');
            setTicker('');
        }
    };

    // Filter the tickers based on the input value and limit the suggestions to 10 items
    const filteredTickers = tickers.filter(ticker =>
        ticker.label.includes(inputValue.toUpperCase())
    ).slice(0, 10);

    // Render the header component
    return (
        <div className="header">
            <img src="logo_valuerise_figma.svg" alt="logo"></img>
            <span className="search">
                <Select
                    value={filteredTickers.find(option => option.value === inputValue)}
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                    options={filteredTickers}
                />
            </span>
        </div>
    );
}

export default Header;