// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background-color: #272343;
    display:flex;
    align-items: center;
    /* justify-content: center; */
    height: 10vh;
}

.header img{
    margin-left: 1rem;
    height: 70%;
    position: relative;
}

.search {
    display: inline-flex;
    width: 50%;
    margin-left: 5vw;
}

.search_input {
    width: 80%;
}

.search_submit_button {
    height: 100%;
    width: 2.5rem;
}

.form-control {
    width: 50%;
}

/* .hamburger {
    visibility: visible;
    margin-right: 1rem;
} */

@media (max-width: 650px) {
    /* .hamburger {
        visibility: hidden;
    } */
    .header {
        flex-direction: column;
        height: 20vh;
    }
    .search {
        margin-left: 0vw;
        width: 70%;
    }
    .header img{
        margin-left: 0rem;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        height: 40%;

    }
}

.search {
    display: block;
    width: 50%; /* Adjust this to change the width */
    margin: 0 auto; /* This centers the element */
}`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;AAEA;;;GAGG;;AAEH;IACI;;OAEG;IACH;QACI,sBAAsB;QACtB,YAAY;IAChB;IACA;QACI,gBAAgB;QAChB,UAAU;IACd;IACA;QACI,iBAAiB;QACjB,qBAAqB;QACrB,kBAAkB;QAClB,WAAW;;IAEf;AACJ;;AAEA;IACI,cAAc;IACd,UAAU,EAAE,oCAAoC;IAChD,cAAc,EAAE,6BAA6B;AACjD","sourcesContent":[".header {\r\n    background-color: #272343;\r\n    display:flex;\r\n    align-items: center;\r\n    /* justify-content: center; */\r\n    height: 10vh;\r\n}\r\n\r\n.header img{\r\n    margin-left: 1rem;\r\n    height: 70%;\r\n    position: relative;\r\n}\r\n\r\n.search {\r\n    display: inline-flex;\r\n    width: 50%;\r\n    margin-left: 5vw;\r\n}\r\n\r\n.search_input {\r\n    width: 80%;\r\n}\r\n\r\n.search_submit_button {\r\n    height: 100%;\r\n    width: 2.5rem;\r\n}\r\n\r\n.form-control {\r\n    width: 50%;\r\n}\r\n\r\n/* .hamburger {\r\n    visibility: visible;\r\n    margin-right: 1rem;\r\n} */\r\n\r\n@media (max-width: 650px) {\r\n    /* .hamburger {\r\n        visibility: hidden;\r\n    } */\r\n    .header {\r\n        flex-direction: column;\r\n        height: 20vh;\r\n    }\r\n    .search {\r\n        margin-left: 0vw;\r\n        width: 70%;\r\n    }\r\n    .header img{\r\n        margin-left: 0rem;\r\n        margin-bottom: 0.5rem;\r\n        margin-top: 0.5rem;\r\n        height: 40%;\r\n\r\n    }\r\n}\r\n\r\n.search {\r\n    display: block;\r\n    width: 50%; /* Adjust this to change the width */\r\n    margin: 0 auto; /* This centers the element */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
