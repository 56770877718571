import React from 'react';
import {Link, Route} from "react-router-dom";
import './Footer.css';

function Footer(props:any){
    return (
        <div className='footer'>
            <div className="nav-items">
                    {/* <li className="nav-item">
                        <Link to="/about">About Us</Link>
                    </li> */}
                    <li className="nav-item">
                        <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/terms">Terms of Use</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/disclaimer">Disclaimer</Link>
                    </li>
                {/* <p>Copyright © 2021 Valuerise.com. All Rights Reserved.</p> */}
            </div>
        </div>
    )
}

export default Footer;