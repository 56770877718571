import React, {useContext, useState} from 'react';
import {Route, Link, RouteProps} from "react-router-dom";
import './LandingPage.css';
import {useTicker} from '../context/TickerContext';
import main_analytics_image from "../images/landing_page_main_analytics.jpg"
import {MdAccountBalance, MdCompare} from "react-icons/md";
import { BsBullseye } from "react-icons/bs";
import {IconContext} from "react-icons";


function LandingPage(props:any){
    const {ticker, setTicker} = useTicker();

    return (

        <div className="headline">
            <div className="headline_element"> 
                <h3> Make the right investments with better analytics </h3>
            </div>
            <div className="headline_element"> 
                <img src={main_analytics_image} className="headline_img" />
            </div>

            {/* <div className="benefits"> 
                <div className="benefit">
                    <IconContext.Provider value={{size:"3rem"}}><MdAccountBalance/></IconContext.Provider>
                    <br></br>
                    <br></br>
                    <h4>Improve your accounting and stock analysis skills</h4>
                    <hr/>
                    <h6>Practice makes perfect. We will guide you along the way. </h6>
                </div>
                <div className="benefit">
                    <IconContext.Provider value={{size:"3rem"}}><BsBullseye/></IconContext.Provider>
                    <br></br>
                    <br></br>
                    <h4>Find the stocks with best fundamentals</h4>
                    <hr/>
                    <h6>Our AI solution will present you with companies with the best fundamentals. This is a great place to start your qualitative research.</h6>
                </div>
                <div className="benefit">
                    <IconContext.Provider value={{size:"3rem"}}><MdCompare/></IconContext.Provider>
                    <br></br>
                    <br></br>
                    <h4>Comparative analysis</h4>
                    <hr/>
                    <h6>Compare the stock to it's own history, to it's industry/sector peers and every other company using common size analysis.</h6>
                </div>
            </div> */}




            {/* <div className="subscription_plans">
                <div className="subscription_plan">
                    <h4>Monthly</h4>
                    <hr/>
                    <h6>$x a month</h6>
                </div>
                <div className="subscription_plan">
                    <h4>Yearly</h4>
                    <hr/>
                    <h6>$x a month</h6>
                </div>
            </div> */}
        </div>
        
    )
}

export default LandingPage;