import React from 'react';
import './LegalDocs.css';

function TermsOfUse(props:any){
    return (
        <div className="big_centered_text_div">
            <h1>Terms of Use & Conditions for Sale</h1>
                <p>Welcome to Valuerise! Valuerise.com, our subscription newsletters and their special websites, our discussion boards, and whatever else we come up with (each a “Service,” and collectively, the "Services"). But first things first: By using the Services, you're agreeing to be bound by these Terms and Conditions (“Terms and Conditions” or “Terms”) INCLUDING THE BINDING ARBITRATION AND CLASS ACTION WAIVER DETAILED IN SECTION 13. These Terms govern your use and access to the website and all our Services. We may change these terms at any time, but we will post a notice on this website of any material changes. Your continued use of Valuerise and its Services means that you accept any new or modified terms and conditions. So please check back here from time to time.</p>
                <p>Some Services are works in progress. We reserve the right to change or even eliminate them with no notice, at our sole discretion.</p>
            <h3>Registration</h3>
                <p>Except as expressly provided in these Terms and Conditions, members may only maintain one active registration with Valuerise. In other words, it's one registration per person. Any other use of multiple accounts or aliases on our Services, including attempts to mislead, defraud, confuse, or otherwise trick us or our members, is a breach of these Terms and Conditions.</p>
                <p>If you subscribe to one of our premium Services, you will be asked to create a password, which you'll need to access the Service. Should you wish to post a message on the discussion boards or comment on an article or a blog, you'll also be required to choose a user name.</p>
            <h3>Premium Services</h3>
                <p>When subscribing to a premium Service such as one of our newsletters, you will need to provide us with your name, email address, and billing and shipping addresses, as well as your credit card information. You agree to pay the applicable subscription fee as set forth on this site. Sales taxes may be charged in addition to the subscription fee. BY PLACING YOUR ORDER, YOU PROVIDE YOUR ELECTRONIC AUTHORIZATION FOR FUTURE CHARGES AGAINST YOUR CREDIT CARD ON FILE UNLESS YOU CANCEL.</p>
                <p>You certify that all information you provide is accurate. You also agree to maintain and update your information as necessary so that it remains accurate and current. In the event that any information you provide is inaccurate or not up to date, The Motley Fool reserves the right to cancel your subscription.</p>
                <p>We reserve the right to refuse or discontinue the supply of a Service to any user at any time at our sole discretion.</p>
                <p>Unless stated otherwise, membership to any Service is for online and/or electronic access only. Certain promotions may offer features that are issued in physical form. Shipping of any goods or service associated with these promotions is limited to the U.S./APO. Physical fulfillment of goods or services not specifically mentioned in a promotion is at the sole discretion of Valuerise.</p>
                <p>Our Services generally deliver their content via email including trade alerts or recommendations, recent actionable investing guidance, Best Buys Now, and other important stock news. There is no opt-out choice available for these emails (unless you cancel your subscription). Of course, you can always opt out of our marketing emails on the settings page.</p>
                <h6>Trial Terms, Automatic Billing and Cancellation Policy</h6>
                    <p>Some Services may provide a free trial or introductory offer. If such an offer is provided, only one per person or household is allowed at any given point in time. In addition, there is a limit of one trial per person and household in any given six-month period unless otherwise stated in the offer.</p>
                    <p>Upon signing up for a free trial, you will have access to the Service for the promotional trial period. If you cancel prior to the end of the promotional offer period, your credit card will not be charged. If you don't cancel your subscription within the trial offer period, we will charge the primary credit card you provided during the sign-up process. You may contact Member Support at support@fool.com to terminate your trial enrollment.</p>
                    <p>Your membership will automatically renew at the then-current rate, until you notify us of your decision to terminate your membership. Most subscriptions will renew for one-year terms, regardless of the offer under which the subscription was initiated. For subscription terms greater than three months we will email you about the length and price for your renewal before we charge you. If you would like to turn off automatic renewal, or extend your subscription for additional years, please contact Member Support. BY ALLOWING FOR YOUR RENEWAL, YOU PROVIDE YOUR ELECTRONIC AUTHORIZATION FOR FUTURE CHARGES AGAINST YOUR CREDIT CARD ON FILE OR APPLE PAY. Accordingly, you agree that your membership fee or subscription will be billed automatically at the beginning of each renewal period at the then-current rate (plus sales tax, if applicable) to the credit card you used in your most recent transaction with us. Any subscription purchased using Apple Pay will continue to renew on Apple Pay.</p>
                    <p>Valuerise reserves the right to increase a Service's fees or institute new charges upon reasonable notice. Service fees are due in advance.</p>
                <h6>Refunds</h6>
                    <p>Different Services (and sometimes specific offers) have different refund policies (including some that offer no refunds). Please review the specific terms of your offer before signing up as you are agreeing to be bound those terms. If you are unsure of your cancellation rights, please contact support.</p>
                    <p>Please note that most refunds are only available to first-time subscribers. If you subscribe to a Service that allows for refunds beyond 30 days, and cancel (and receive a refund) and then subscribe again, you will not receive a refund after the first 30 days.</p>
                <h6>Transfer</h6>
                    <p>You cannot transfer your subscription to someone else, except through bequeathment or by a court order. In the event a subscription is transferred, it will continue through the end of its term and can be renewed at the then-current price and conditions. For assistance in this situation, please contact Member Services.</p>
            <h3>Intellectual Property</h3>
                <p>All of the Content on our websites and any Service we provide is protected by U.S. and international copyright laws and is the property of Valuerise and/or providers of the content under license. By "Content" we mean any information, mode of expression, or other materials and services found on Valuerise. This includes message boards, blogs, ratings, podcasts, chats, software, our writings, graphics, videos, and any and all other features.</p>
                <p>You may make one copy of the Content for your personal, non-commercial use, provided that any material copied remains intact and includes the following notice: "Copyright 1995-2018 [or whatever the current year is] Valuerise. All rights reserved." Any other copying, distribution, storing, or transmission of any kind, or any commercial use of our Content, is prohibited without Valuerise's prior written permission. That means you may not sell, auction, transfer or barter your subscription or any individual publication.</p>
                <p>You also may not republish, post, transmit, or distribute the Content to online bulletin and message boards, blogs, chat rooms, intranets, or anywhere else without our consent. You further agree not to create abstracts from or scrape our Content, for use on another website or service (including our own personal forums and blogs). In short, you may not post our Content anywhere else online. Aside from opening yourself up to liability, distributing our premium Services, stock picks, and performance data to other sites and forums is unfair to our members who pay good money to receive our Content. So please don't do it.</p>
                <p>Please note that notwithstanding the foregoing, when you post content (such as a message-board post), you are not somehow surrendering your copyright in your expression, but you are granting us an unlimited license to use it. Specifically, by posting content, you agree that Valuerise has an irrevocable, perpetual, and worldwide license to use republish, distribute, reproduce, display, communicate to the public, adapt, perform, store, translate, sublicense, and promote anything you post on our websites. This includes the rights to syndicate and make derivative works out of your content. If you don't want us to use or republish your content, then please don't post it on our websites. We'll only republish your content in context, and we'll credit you as author (unless we're using small quotations). We won't republish your posts in advertising without your permission. </p>
            <h3>Conduct</h3>
                <p>Valuerise champions active and open debate among our members. All we ask is that it's done in a lawful and civil manner -- be it posting on our boards, commenting on blogs or articles, or using our system to contact a fellow member in any way.</p>
                <p>Accordingly, you agree to use Valuerise for lawful purposes only. You may not use or allow others to use your Valuerise membership to:</p>
                    <li>Post or transmit any content that is disruptive, uncivil, abusive, vulgar, profane, obscene, hateful, fraudulent, threatening, harassing, defamatory, or which discloses private or personal matters concerning any person;</li>
                    <li>Post or transmit any material that you don't have the right to transmit under law (such as copyrights, trade secrets, or securities) or under contractual or fiduciary relationships (such as nondisclosure agreements);</li>
                    <li>Post, transmit, or link to sexually explicit material;</li>
                    <li>Impersonate any person, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                    <li>Post or transmit any advertising, promotional materials, or other forms of solicitation, including chain letters and pyramid schemes;</li>
                    <li>Violate any applicable law or regulation while accessing and using our sites, including, without limitation, the rules and regulations of the U.S. Securities and Exchange Commission and the national or other securities exchanges (especially and including the rule against making false or misleading statements to manipulate the price of any security);</li>
                    <li>Offer, sell, or buy any security;</li>
                    <li>Post or transmit any file that contains viruses, corrupted files, "Trojan Horses," or any other contaminating or destructive features that may damage someone's computer;</li>
                    <li>Forge headers or manipulate identifiers or other data in order to disguise the origin of any content transmitted through our sites or to manipulate your presence on our sites;</li>
                    <li>Use any automated means, including, without limitation, agents, robots, scripts, or spiders, to access, monitor, copy or harvest data from any part of our sites;</li>
                    <li>Take any action that imposes an unreasonably or disproportionately large load on our infrastructure or disrupts the functioning of our systems or Services; and</li>
                    <li>Take any action that damages or disrupts the functioning of our systems or Services.</li>
                <p>Unauthorized access of our sites is a breach of these Terms and Conditions and a violation of the law. You agree not to access our sites by any means other than through the interfaces we provide for use in our accessing our sites.</p>
                <p>Valuerise may at any time, without prior notice and at our sole discretion, remove any post, terminate any membership, or take any action for violating the above (and, if we may say so, sensible) provisions or otherwise taking an action disruptive to a Service. In the event Valuerise terminates your premium Service, you will receive a prorated refund at our discretion.</p>
                <p>You are responsible for statements made and actions taken through the use of your password, so please maintain the confidentiality of your password. You agree to immediately notify Member Support of any actual or suspected unauthorized use of your username and password. We will not be responsible for any loss to you arising from unauthorized use of your data. </p>
            <h3>No Personalized Advice</h3>
                <p>We want to help you make money. However, Valuerise is not in the business of rendering personalized investment advice. We can't know all the relevant facts about you and your individual needs, and we cannot claim or represent that any particular Services are suitable for you. Accordingly, you agree that any recommendation or action taken by a Service does not constitute a recommendation that a particular security, strategy, or action is suitable for you. If you want personal advice, then you should seek a registered investment advisor.</p>
                <p>Valuerise may partner with third parties in order to make buying our Services' recommendations easier for you. We will not, and cannot, trade on your behalf at your brokerage. Only you can decide whether or not a stock is right for you and you agree to be liable for any trades you initiate at your brokerage using tools that we or our partners provide.</p>
                <p>Affiliates of Valuerise provide individualized investment advice and investment products. These companies may recommend or hold securities mentioned in our publications. Editorial personnel have no nonpublic knowledge of our affiliates' holdings and/or specific recommendations, and the affiliates’ personnel have no knowledge of any editorial content before it is published.</p>
            <h3>You Bear Responsibility for Your Financial and Investment Decisions</h3>
                <p>One of the principal tenets here at Valuerise is that the best person to handle your finances is you. By your use of our Services, you're agreeing that you bear responsibility for your own investment research and investment decisions. You also agree that Valuerise, its directors, its employees, and its agents will not be liable for any investment decision made or action taken by you and others based on news, information, opinion, or any other material published through our Services.</p>
            <h3>Disclaimer of Warranties and Liabilities</h3>
                <p>Valuerise provides a very broad range of information and commentary via its many Services. Consequently, as a result of the diverse opinions of our staff, an Valuerise Service may, from time to time, take actions or issue recommendations with regard to specific securities that are different from those taken or issued by another Service we provide.</p>
                <p>Valuerise does not warrant the completeness or accuracy of the Content found in our Services or its usefulness for any particular purpose. And although we have the hardest-working, most talented techies in the business, Valuerise makes no promises that our content or any of the Services will be delivered to you on an uninterrupted, timely, secure, or error-free basis. In fact, we're not making any promises or warranties except that we'll do our best to provide interesting and helpful information, education, and entertainment. </p>
                <p>You agree that under no circumstances will Valuerise, its employees, or its agents be liable for direct, indirect, incidental, consequential, punitive, or any other type of damages resulting from your use, purchase, or downloading of any material on our Websites, or arising from or related to these Terms or the Privacy Policy, even if we have been advised of the possibility of such damages. This includes, but is no way limited to, loss or injury caused in whole or in part by our negligence or by stuff beyond our control in creating or delivering any of our Services. This disclaimer of liability does not apply in New Jersey.</p>
                <p>Valuerise relies on various sources of information that we believe to be accurate and reliable. There are also literally thousands of contributors here -- most, we believe, with incredibly interesting and insightful information and opinions to share. But we can't and won't take responsibility for, or make any claims or representations about, the accuracy, completeness, or even the truth of every bit of data, information, and opinion provided through our Services. Remember: All information and content provided on or by Valuerise is to be used on an "as is, with all faults" basis.</p>
                <h6>Disclaimers of Liability</h6>
                    <p>EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, THE WEBSITE AND PRODUCTS AND SERVICES CONTAINED AND OFFERED ON THE WEBSITE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. NEITHER THE MOTLEY FOOL NOR ANY OF OUR PARTNERS MAKES ANY WARRANTY THAT THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL THAT YOU PURCHASE OR OBTAIN FROM THE USE OF OUR SERVICES WILL MEET YOUR EXPECTATIONS OR THAT AND ANY ERRORS WILL BE IMMEDIATELY CORRECTED. NEITHER WE NOR ANY OF OUR PARTNERS MAKES ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO OUR WEBSITES, ANY CONTENT, OR ANY OF OUR SERVICES, TOOLS, PRODUCTS, OR PROPERTIES. YOU EXPRESSLY AGREE THAT YOU WILL ASSUME THE ENTIRE RISK AS TO THE QUALITY AND THE PERFORMANCE OF OUR SERVICES AND THE ACCURACY OR COMPLETENESS OF THEIR CONTENT.</p>
                    <p>SOME STATES DO NOT ALLOW EXCLUSION OF IMPLIED WARRANTIES OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH STATES, OUR LIABILITY AND THAT OF OUR THIRD-PARTY CONTENT PROVIDERS WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW. THIS DISCLAIMER OF WARRANTIES DOES NOT APPLY IN NEW JERSEY.</p>
            <h3>Communication</h3>
                <p>Federal or state law may require that we notify you of certain events. You hereby acknowledge and consent that such notices will be effective upon our posting them on our websites or sending them to you via email (another reason to please keep your account settings current). If you do not provide us with accurate information, we cannot be held liable if we fail to notify you.</p>
            <h3>Dispute Resolution by Binding Arbitration and Class Action Waiver</h3>
                <p>If a dispute arises in connection with your use of our Services or in relation to any of these Terms (collectively, “Disputes”) our hope is that we can resolve the matter informally. Accordingly, in the event of a Dispute, we agree to first contact each other via email with a description of the Dispute and any proposed resolution. You will email support@Valuerise.com with your concern and Valuerise will contact you via the email address we have on file for you.</p>
                <p>If a Dispute cannot be resolved informally, we each agree that except as provided below, the Dispute will be submitted to final and binding arbitration before a panel of three arbitrators of the American Arbitration Association (“AAA”) in a location convenient to you. Either party may commence the arbitration process by submitting a written demand for arbitration with the AAA, and providing a copy to the other party. The arbitration will be conducted in accordance with the provisions of the AAA’s Commercial Dispute Resolutions Procedures, Supplementary Procedures for Consumer-Related Disputes, in effect at the time of submission of the demand for arbitration. We will pay all of the filing costs, including arbitrator fees. Judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. You expressly agree to refrain from bringing or joining any claims in any representative or class-wide capacity, including but not limited to bringing or joining any claims in any class action or any class-wide arbitration.</p>
                <p>YOU UNDERSTAND THAT YOU ARE WAIVING YOUR RIGHT TO HAVE A JUDGE OR JURY DECIDE YOUR CASE AND TO BE PARTY TO A CLASS OR REPRESENTATIVE ACTION. YOU UNDERSTAND AND AGREE TO HAVE ANY CLAIMS DECIDED INDIVIDUALLY AND ONLY THROUGH ARBITRATION.</p>
                <p>EXCEPTIONS</p>
                <p>Notwithstanding the foregoing, the following will not be subject to arbitration and may be adjudicated only in the state and federal courts of Virginia: (i) any dispute, controversy, or claim related to or contesting the validity of our proprietary rights, including without limitation, trademarks, service marks, copyrights, patents, or trade secrets; or (ii) an action by a party for temporary, preliminary, or permanent injunctive relief, whether prohibitive or mandatory, or other provisional relief. You may also file an individual action in a small claims court in lieu of arbitration.</p>
                <p>OPTING OUT</p>
                <p>You will have thirty (30) days from the date you submit your personally identifiable information to opt out of this arbitration agreement. To opt out of arbitration, you must contact us. If more than thirty (30) days have passed, you are not eligible to opt out of arbitration.</p>
            <h3>Your Agreement to These Terms</h3>
                <p>You acknowledge and agree that by agreeing to these Terms electronically you are expressly agreeing to the terms set forth herein. You acknowledge that your electronic submission constitutes your agreement and intent to be bound by these Terms and Conditions.</p>
                <p>If any provision in these Terms and Conditions is found to be invalid, unenforceable, or nonsensical, the remaining provisions will continue in full force and effect. This agreement is and always will be governed by the laws of the United States of America and the Commonwealth of Virginia (except with respect to choice of law).You agree that the proper forum for any claim arising thereunder will be the U.S. Court for the Eastern District of Virginia or the appropriate Virginia court.</p>
                <p>Our Services are directed at a U.S. audience. We cannot warrant that the Services are appropriate for users outside the United States or that use of the Services is permitted under the laws of other jurisdictions. All personal data is maintained in the United States under the terms of our Privacy Policy, which is also incorporated by reference.</p>
                <p>Except as otherwise expressly stated in these Terms and Conditions, there are no third-party beneficiaries to this agreement.</p>
                <p>These Terms and Conditions, including those that are incorporated by reference, constitute the entire and only agreement between you and Valuerise and govern your use of our sites.</p>
                <br></br>
                <p>Last Updated: Jan 01, 2021</p>
        </div>
    )
}

export default TermsOfUse;