import React from 'react';
import './LegalDocs.css';

function AboutUs(props:any){
    return (
        <div className="big_centered_text_div">
            <h3>Valuerise</h3>
            <p>Empowering you to become a better investor.</p>
        </div>
    )
}

export default AboutUs;