import React, {createContext, useContext} from "react";

export type TickerContextType = {
    ticker: string;
    setTicker: any;
}

export const TickerContext = React.createContext<TickerContextType>({
    ticker: "",
    setTicker: () => console.warn("No ticker provided.")
});

// custom hook to make the consumption of the context and its setter function more convenient
export const useTicker = () => useContext(TickerContext); 