// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation_bar {
    display: flex;
    width: 100vw;
    padding: 0rem 0rem;
}

.nav-items {
    width: 100vw;
    display:flex;
    list-style-type: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-inline-start: 0px;
    background-color: lightgrey;
    margin-top: 0px;
}

@media (min-width: 650px) {
    .nav-items {
        flex-direction: row;
    }
}

.nav-items li {
    /* margin: 0.2rem; */
    /* display: inline-block; */
    padding: 5px 10px;
    /* border: 2px solid black; */
}





`, "",{"version":3,"sources":["webpack://./src/components/NavBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,qBAAqB;IACrB,eAAe;IACf,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,yBAAyB;IACzB,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI,oBAAoB;IACpB,2BAA2B;IAC3B,iBAAiB;IACjB,6BAA6B;AACjC","sourcesContent":[".navigation_bar {\r\n    display: flex;\r\n    width: 100vw;\r\n    padding: 0rem 0rem;\r\n}\r\n\r\n.nav-items {\r\n    width: 100vw;\r\n    display:flex;\r\n    list-style-type: none;\r\n    flex-wrap: wrap;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    padding-inline-start: 0px;\r\n    background-color: lightgrey;\r\n    margin-top: 0px;\r\n}\r\n\r\n@media (min-width: 650px) {\r\n    .nav-items {\r\n        flex-direction: row;\r\n    }\r\n}\r\n\r\n.nav-items li {\r\n    /* margin: 0.2rem; */\r\n    /* display: inline-block; */\r\n    padding: 5px 10px;\r\n    /* border: 2px solid black; */\r\n}\r\n\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
