import React, {useState} from 'react';
import './App.css';
import PowerBIReport from "./components/PowerBIReport";
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import News from "./components/NewsByCompany";
import Footer from "./components/Footer";
import {TickerContext} from "./context/TickerContext";
import {BrowserRouter} from "react-router-dom";
import LandingPage from './components/LandingPage';
// import {reportRender} from "./components/NavBar";
import {Route} from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import Disclaimer from "./components/Disclaimer";
import AboutUs from "./components/AboutUs";
import InvalidTicker from "./components/InvalidTicker";


function App() {
  const [ticker, setTicker] = useState("");
  document.title = "Valuerise"
  

  return (
    <div className="App">
      <BrowserRouter>
        <TickerContext.Provider value={{ticker, setTicker}}>
          <React.Fragment>
            <Header></Header>
            <NavBar></NavBar>
            {/* <News></News> */}
            {/* <PowerBIReport ticker={ticker} pageName="ReportSection19b1541878z1395224a8f"></PowerBIReport> */}
            
            
            <Route path="/" exact render={() => ticker == "" ? <LandingPage></LandingPage> : <PowerBIReport pageName={process.env.REACT_APP_PBI_REPORT_PAGE_ENTITY}/>}/>
            
            <Route path="/privacy" exact component={PrivacyPolicy}/>
            <Route path="/terms" exact component={TermsOfUse}/>
            <Route path="/disclaimer" exact component={Disclaimer}/>
            <Route path="/about" exact component={AboutUs}/>

            <Route path="/overview" exact render={() => ticker == "" ? <InvalidTicker></InvalidTicker> : <PowerBIReport pageName={process.env.REACT_APP_PBI_REPORT_PAGE_ENTITY}/>}/>
            <Route path="/balance_sheet" exact render={() => ticker == "" ? <InvalidTicker></InvalidTicker> : <PowerBIReport pageName={process.env.REACT_APP_PBI_REPORT_PAGE_BALANCE_SHEET}/>}/>
            <Route path="/income_statement" exact render={() => ticker == "" ? <InvalidTicker></InvalidTicker> : <PowerBIReport pageName={process.env.REACT_APP_PBI_REPORT_PAGE_INCOME_STATEMENT}/>}/>
            <Route path="/cashflow_statement" exact render={() => ticker == "" ? <InvalidTicker></InvalidTicker> : <PowerBIReport pageName={process.env.REACT_APP_PBI_REPORT_PAGE_CASHFLOW_STATEMENT}/>}/>
            <Route path="/metrics" exact render={() => ticker == "" ? <InvalidTicker></InvalidTicker> : <PowerBIReport pageName={process.env.REACT_APP_PBI_REPORT_PAGE_METRICS}/>}/>
            <Route path="/per_share_view" exact render={() => ticker == "" ? <InvalidTicker></InvalidTicker> : <PowerBIReport pageName={process.env.REACT_APP_PBI_REPORT_PAGE_PER_SHARE}/>}/>
            <Route path="/liquidity" exact render={() => ticker == "" ? <InvalidTicker></InvalidTicker> : <PowerBIReport pageName={process.env.REACT_APP_PBI_REPORT_PAGE_LIQUIDITY}/>}/>

      
            <Footer></Footer>
          </React.Fragment>
        </TickerContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
