import React from 'react';

function InvalidTicker(props:any){
    return (
        <div>
            <h1>Invalid Ticker</h1>
            <h4>Search for a valid ticker to begin</h4>
        </div>
    )
}

export default InvalidTicker;