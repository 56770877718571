import React, {useContext, useState} from 'react';
import {Route, Link, RouteProps} from "react-router-dom";
import './NavBar.css';
import {useTicker} from '../context/TickerContext';
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'


function NavBar(props:any){
    const {ticker, setTicker} = useTicker();
    const [page, setPage] = useState<String>();

    return (

        <div className="body" data-navigation="navigate">
            {/* <nav className = "navigation_bar"> */}
            <ul className="nav-items">
                <li className="nav-item">
                    <Link to={{pathname:"/overview", search: "?ticker=".concat(ticker)}} onClick={() => setPage(process.env.REACT_APP_PBI_REPORT_PAGE_ENTITY)}>Overview</Link>
                </li>
                <li className="nav-item">
                    <Link to={{pathname:"/balance_sheet", search: "?ticker=".concat(ticker)}} onClick={() => setPage(process.env.REACT_APP_PBI_REPORT_PAGE_BALANCE_SHEET)}>Balance Sheet</Link>
                </li>
                <li className="nav-item">
                    <Link to={{pathname:"/income_statement", search: "?ticker=".concat(ticker)}} onClick={() => setPage(process.env.REACT_APP_PBI_REPORT_PAGE_INCOME_STATEMENT)}>Income Statement</Link>
                </li>
                <li className="nav-item">
                    <Link to={{pathname:"/cashflow_statement", search: "?ticker=".concat(ticker)}} onClick={() => setPage(process.env.REACT_APP_PBI_REPORT_PAGE_CASHFLOW_STATEMENT)}>Cashflow Statement</Link>
                </li>
                <li className="nav-item">
                    <Link to={{pathname:"/metrics", search: "?ticker=".concat(ticker)}} onClick={() => setPage(process.env.REACT_APP_PBI_REPORT_PAGE_METRICS)}>Metrics</Link>
                </li>
                <li className="nav-item">
                    <Link to={{pathname:"/per_share_view", search: "?ticker=".concat(ticker)}} onClick={() => setPage(process.env.REACT_APP_PBI_REPORT_PAGE_PER_SHARE)}>Per Share View</Link>
                </li>
                <li className="nav-item">
                    <Link to={{pathname:"/liquidity", search: "?ticker=".concat(ticker)}} onClick={() => setPage(process.env.REACT_APP_PBI_REPORT_PAGE_LIQUIDITY)}>Liquidity</Link>
                </li>
            </ul>
        {/* </nav>  */}

        
        </div>
        
    )
}



export default NavBar;
// export {reportRender};